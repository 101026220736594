<template>
  <div class="private-wrap">
    <h1>
      개인정보 처리방침
    </h1>

    <p>
      주식회사 뷰노(이하 '회사'라 한다)는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있음을 알려드립니다.
    </p>

    <p>
      ※ 본 방침은 2024년 12월 09일부로 개정 시행됩니다.
    </p>

    <p v-if="$store.state.showOldPrivate">
      ※ 2024년 12월 09일 이전 까지의 <a href="/home/private_240212.pdf" target="_blank">개인정보 처리방침 확인</a>
    </p>

    <h2>
      제 1 조 개인정보의 처리 목적
    </h2>

    <p>
      회사는 개인정보를 다음의 목적을 위해 처리합니다
    </p>

    <ol>
      <li>
        웹사이트상 서비스 제공
        <ul>
          <li>
            Submit Inquiries(문의사항 남기기): 데모 신청을 포함한 질의 답변 및 불만 처리, 분쟁 대응
          </li>
          <li>
            VUNO Academy: 웨비나 서비스 제공
          </li>
          <li>
            뷰노몰: 서비스 제공에 관한 계약이행 및 요금정산, 회원관리, 마케팅 및 광고
          </li>
        </ul>

      </li>
      <li>
        임상시험을 비롯한 각종 연구의 실시: 연구자 신원 확인, 연구 수행을 위한 관계 법령에 대한 지식, 연구 수행을 위한 적절한 교육, 훈련, 경험, 자격 보유 여부 검증 등 계약 체결 여부 결정, 자문료/강연료 등 용역대금의 지급 등 계약의 이행, 계약과 관련된 분쟁 및 불만의 처리, 계약의 적법한 체결 및 이행사실에 대한 증빙, 계약 상대방, 계약내용, 대금지급내역 등에 대한 전산관리
      </li>
      <li>
        영업활동: 방문, 전화, 우편(전자우편 포함), 문자/카카오톡 발송, 웨비나 진행 등 다양한 온라인 및 오프라인 채널을 통한 보건의료전문가를 대상으로 하는 마케팅, 회사 제품 및 서비스 안내, 시장조사, 학술대회/이벤트 안내 및 진행을 포함한 의학적 정보 전달 및 취득 활동의 수행      </li>
      <li>
        거래 활동: 계약의 체결 및 이행: 업무담당자 식별 및 확인, 제품∙용역의 공급 및 대금 수령 등 계약의 이행, 계약과 관련된 분쟁 및 불만의 처리, 관련 업무연락, 계약내용, 거래현황, 제품∙용역 공급 및 대금지급내역 등에 대한 전산관리, 계약의 종료 및 갱신 여부에 대한 판단 및 관련 업무 진행, 동아리 등 후원 단체와 협약 체결 및 후원금 지급, 후원협약에 따른 혜택 제공
      </li>
      <li>
        민원 처리 및 안전성정보 관리: 제품 관련 문의에 대한 응답, 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보, 제품의 안전성정보와 품질불만의 보고, 평가 및 관리 등을 목적으로 개인정보를 처리합니다.      </li>
      <li>
        인재 채용: 지원자의 신원 및 학력경력의 확인, 채용 여부의 결정 및 통지 기타 채용 관련 업무, 추가 채용 시 지원의사 확인 기타 추가 채용 관련 업무, 과거 지원이력 관리, 부적격자 반복지원 방지, 채용시 고용계약의 체결 및 유지를 위한 제반 업무      </li>
      <li>
        미디어 활동: 각종 인터뷰, 회사 제품 관련 제보, 보도자료 배포 등 미디어 활용
      </li>
      <li>
        법적·행정적 의무 이행: 의료법·의료기기법·생명윤리 및 안전에 관한 법률 등 관계법령에 따른 임상연구, 부작용 보고 및 자료제출의무의 이행, 의료기기 거래에 관한 공정경쟁규약에 의한 자문/강연 신고의무 이행, 법인세·부가가치세 등 각종 세금의 신고 및 납부, 영수증·세금계산서의 발급·교부 등 법령 및 그에 근거한 행정관청·관계기관 등의 명령·처분 등에 따라 회사에 부과되는 각종 법적·행정적 의무의 이행
      </li>
      <li>
        개인영상정보 처리: 시설안전, 화재 및 범죄예방, 물류 작업 투명성 확보 및 민원 대응
      </li>
      <li>
        Hativcare Application: 회원가입, 측정, 서비스 신청 및 상담 활동의 수행
      </li>
    </ol>

    <p>
      또한, 회사는 AI 소프트웨어 개발, 연구 진행 목적으로 가명화된 의료데이터를 처리합니다. 연구에 활용되는 의료데이터에는 성명, 연락처 등 개인의 신원을 알 수 있는 정보는 포함되어 있지 않으며, 회사는 과학적 연구 목적으로만 해당 데이터를 이용하며, 특정 개인을 알아보기 위한 목적으로 가명정보를 처리하지 않습니다.
    </p>
    <p>
      한편, 회사는 개인정보 보호법에 따라, 정보주체에게 불이익이 발생하는지 여부, 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부 등을 고려하여, 당초 수집 목적과 합리적으로 관련된 범위 내에서 개인정보를 이용·제공할 수 있습니다. 구체적인 고려사항은 다음과 같으며, 회사는 개인정보 보호법 등 관련 법령, 개인정보의 이용·제공 목적, 개인정보가 이용·제공되는 방식, 이용·제공하려는 개인정보 항목, 정보주체가 동의한 사실이 있거나 정보주체에게 고지·공개된 사항이 있는 경우 그 내용, 이용·제공에 따라 정보주체에게 미치는 영향, 대상 정보를 보호하기 위해 취해져 있는 조치 등을 포함한 제반 사정을 종합하여 이용제공할 것인지 여부를 신중히 판단할 것입니다:
    </p>

    <ul>
      <li>
        당초 수집 목적과 관련성이 있는지 여부
      </li>
      <li>
        개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측 가능성이 있는지 여부
      </li>
      <li>
        정보주체의 이익을 부당하게 침해하는지 여부
      </li>
      <li>
        가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
      </li>
    </ul>

    <h2>
      제 2 조 개인정보의 처리 및 보유기간
    </h2>

    <ol>
      <li>
        회사는 법령에 따른 개인정보 보유 및 이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유 및 이용기간 내에서 처리목적이 달성될 때까지 개인정보를 보유 및 이용하며, 그 목적이 달성되면 지체 없이 파기됩니다.
        <ul>
          <li>
            웹사이트상 서비스 제공
          </li>
          <li>
            Submit Inquiries(문의사항 남기기): 질의를 제출한 날로부터 3년간
          </li>
          <li>
            VUNO Academy: 회원탈퇴시까지
          </li>
          <li>
            뷰노몰: 회원탈퇴시까지
          </li>
          <li>
            임상시험을 비롯한 각종 연구의 실시: 의료기기 임상시험인 경우 제조/변경 허가일로부터 3년간, 이외의 경우 연구 완료일부터 3년간, 단, 자문/강연 관련 정보는 자문/강연 완료일부터 5년간
          </li>
          <li>
            영업활동: 수집일로부터 3년간
          </li>
          <li>
            거래 활동: 계약 종료시까지
          </li>
          <li>
            민원처리: 수집일로부터 3년간
          </li>
          <li>
            인재 채용: 채용 여부가 확정된 때로부터 1년간
          </li>
          <li>
            미디어 활동: 수집일로부터 3년간
          </li>
          <li>
            법적·행정적 의무 이행: 관련 법령에서 정한 의무의 이행이 완료될 때까지 (의료기기 이상사례 보고의 경우 보고일로부터 3년)
          </li>
          <li>
            개인영상정보 처리: 수집일로부터 180일간
          </li>
          <li>
            Hativcare Application: 회원 탈퇴시까지
          </li>
        </ul>
        가명처리된 의료데이터의 경우 과학적 연구 목적으로 이용되며, 연구 종료시까지 보유 및 이용됩니다.
      </li>
      <li>
        또한, 회사는 상법, 통신비밀보호법 등 관련 법령에 따라 개인정보보호가 필요한 경우 관련 법령에 명시된 보유 및 이용 기간 동안 해당 정보를 보유합니다.
        <ul>
          <li>
            회사의 영업에 관한 중요서류 <br>
            보존 근거: 상법 <br>
            보존 기간: 10년
          </li>
          <li>
            대금결제 및 재화 등의 공급에 관한 기록 및 계약 또는 청약철회 등에 관한 기록 <br>
            보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br>
            보존 기간 : 5년
          </li>
          <li>
            소비자의 불만 또는 분쟁 처리에 관한 기록 <br>
            보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br>
            보존 기간 : 3년
          </li>
          <li>
            표시·광고에 관한 기록 <br>
            보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br>
            보존 기간 : 6개월
          </li>
          <li>
            웹사이트 방문기록: <br>
            보존 근거: 통신비밀보호법 <br>
            보존 기간: 3개월
          </li>
          <li>
            임상시험계획서와 임상시험 실시에 관한 기록 및 자료 <br>
            보존 근거: 의료기기법 <br>
            보존 기간: 3년
          </li>
          <li>
            인간대상연구와 관련한 기록 <br>
            보존 근거: 생명윤리법 <br>
            보존 기간: 3년
          </li>
        </ul>
      </li>
    </ol>

    <h2>
      제 3 조 개인정보의 제3자 제공
    </h2>

    <ol>
      <li>
        회사는 원칙적으로 개인정보를 본 처리방침 제1조에서 명시한 범위 내에서 처리하며, 정보주체의 사전 동의 없이는 본래의 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. 다만, 아래 각 항의 경우에는 예외로 합니다.
        <ul>
          <li>
            정보주체가 사전에 제3자 제공 및 공개에 동의한 경우
          </li>
          <li>
            법령 등에 의해 제공이 요구되거나 허용되는 경우
          </li>
        </ul>
      </li>
      <li>
        회사의 개인정보 제3자 제공 현황은 다음과 같습니다.
      </li>
    </ol>

    <table>
      <caption>
        [고객(보건의료인) 정보]
      </caption>
      <tr>
        <th>
          제공받는 자
        </th>
        <th>
          제공목적
        </th>
        <th>
          제공항목
        </th>
        <th>
          보유∙이용기간
        </th>
      </tr>
      <tr>
        <td>
          해당 제품 판매담당 지역 대리점 (상세)
          <ul>
            <li>
              FundusAI: 안국약품(주)
            </li>
            <li>
              DeepASR: 주식회사 퍼즐에이아이
            </li>
            <li>
              BoneAge: 주식회사 스피드덴탈 등 담당 대리점
            </li>
          </ul>
        </td>
        <td>
          의료기기의 구입문의에 따른 대응, 제품에 대한 설명제공 및 유지보수
        </td>
        <td>
          병원명, 고객명, 전공, 이메일주소, 전화번호
        </td>
        <td>
          이용목적 달성시까지
        </td>
      </tr>
    </table>

    <table>
      <caption>
        [연구자 정보]
      </caption>
      <tr>
        <th>
          제공받는 자
        </th>
        <th>
          제공목적
        </th>
        <th>
          제공항목
        </th>
        <th>
          보유∙이용기간
        </th>
      </tr>
      <tr>
        <td rowspan="2">
          식품의약품안전처 <br>
          (1577-1255)
        </td>
        <td>
          의료기기법 제10조에 따른 임상시험계획승인의 신청
        </td>
        <td>
          성명, 소속, 전화번호
        </td>
        <td>
          목적 달성시까지
        </td>
      </tr>
      <tr>
        <td>
          의료기기 임상시험 수행과 관련한 의료기기법 이하 관련 법령에 따른 의뢰자 의무사항 준수
        </td>
        <td>
          임상시험 참여 연구자의 자격기준 (시험책임자, 시험담당자, 공동연구자의 성명, 소속 기관명, 전공, 직위, 전화번호, 관련 경력, 학력, 임상시험 교육이력 등)
        </td>
        <td>
          의료기기법 시행규칙 제24조 및 별표3 의료기기 임상시험 관리기준에 따른 보관기간까지
        </td>
      </tr>
      <tr>
        <td>
          보건복지부 <br>
          (129)
        </td>
        <td>
          관련 법령에 따른 경제적 이익 등 제공내역에 관한 지출보고서 제출
        </td>
        <td>
          성명, 소속
        </td>
        <td>
          의료기기법 제13조의2 제1항에 따른 지출보고서 작성일부터 5년간
        </td>
      </tr>
      <tr>
        <td>
          한국의료기기산업협회 <br>
          (KMDIA, 02-596-7404)
        </td>
        <td>
          의료기기 공정경쟁규약에 따른 분기별 신고의무 이행상황 및 공정경쟁규약상 강연/자문료 상한액 기준 준수 여부 점검
        </td>
        <td>
          강연: 강연일자, 강연장소, 행사명 등 강연 의뢰목적, 강연자 성명 및 소속, 강연료 지급액, 강연주제 <br>
          자문: 자문일자 또는 자문기간, 행사명 등 자문 의뢰 목적, 자문자 성명 및 소속, 자문료 지급액, 자문주제
        </td>
        <td>
          강연 또는 자문일이 속한 연도의 다음 연도 1월 1일부터 기산하여 5년이 되는 시점까지
        </td>
      </tr>
      <tr>
        <td>
          연구자가 참여한 각 병원의 윤리위원회/임상 시험 심사위원회
        </td>
        <td>
          관련 법령에 따른 임상시험 계획 심의 신청 및 변경/종료/결과 보고, 이상반응 및 미준수 사항 보고 등 각종 보고의무 수행
        </td>
        <td>
          임상시험 연구자의 자격기준 (시험책임자, 시험담당자, 공동연구자의 성명, 소속, 전공, 직위, 전화번호, 관련경력, 학력, 임상시험 교육 이력 등) 임상시험 대상자의 나이, 성별, 병력, 활력징후 및 연구 과정에서 수행되는 검사 결과 등 건강정보
        </td>
        <td>
          관련법령에 따른 보유기한까지
        </td>
      </tr>
      <tr>
        <td>
          중소기업청, <br>
          한국산업단지관리공단, <br>
          창업진흥원, <br>
          보건산업진흥원, <br>
          한국국제협력단, <br>
          한국보건산업진흥원, <br>
          한국산업기술평가관리원, <br>
          정보통신산업진흥원, <br>
          과학기술정보통신부, <br>
          한국산업기술진흥원, <br>
          한국화학융합시험연구원, <br>
          한국소프트웨어산업협회, <br>
          정보통신기술진흥원, <br>
          중소벤처기업부, <br>
          한국기술벤처재단, <br>
          식약처, <br>
          보건복지부
        </td>
        <td>
          국책과제 수행을 위한 협약서 등 서류 제출
        </td>
        <td>
          참여기관 담당자의 성명, 소속, 연락처
        </td>
        <td>
          해당 과제가 완료될 때까지
        </td>
      </tr>
      <tr>
        <td>
          국세청 <br>
          (126)
        </td>
        <td>
          소득세 등 각종 세금의 신고납부, 소득세법에 따른 연말정산의 실시
        </td>
        <td>
          성명
        </td>
        <td>
          목적 달성시까지
        </td>
      </tr>
    </table>

    <table>
      <caption>
        [주주 정보]
      </caption>
      <tr>
        <th>
          제공받는 자
        </th>
        <th>
          제공목적
        </th>
        <th>
          제공항목
        </th>
        <th>
          보유∙이용기간
        </th>
      </tr>
      <tr>
        <td>
          금융감독원, 한국거래소
        </td>
        <td>
          공시의무 수행을 위한 주주의 개인정보 제공
        </td>
        <td>
          주요주주 이름, 지분율, 보유주식수
        </td>
        <td>
          관련법령에 따른 보유기한까지
        </td>
      </tr>
    </table>

    <table>
      <caption>
        [이상사례 정보]
      </caption>
      <tr>
        <th>
          제공받는 자
        </th>
        <th>
          제공목적
        </th>
        <th>
          제공항목
        </th>
        <th>
          보유∙이용기간
        </th>
      </tr>
      <tr>
        <td>
          식품의약품안전처를 비롯한 회사 제품의 인허가 취득국의 보건의료당국
        </td>
        <td>
          이상반응에 대한 관련 법령에 따른 보고
        </td>
        <td>
          연구대상자의 성명이니셜, 성별, 생년월일, 나이, 키, 몸무게 등 건강관련정보 일체
        </td>
        <td>
          관련 규정에 따른 보유기한까지
        </td>
      </tr>
    </table>

    <h2>
      제 4 조 개인정보처리 위탁
    </h2>

    <ol>
      <li>
        회사는 원활한 개인정보 업무처리를 위하여 아래와 같이 개인정보 처리업무를 위탁하고 있습니다.
        <table>
          <tr>
            <th>
              수탁자
            </th>
            <th>
              위탁업무의 내용
            </th>
          </tr>
          <tr>
            <td>
              ㈜두들린
            </td>
            <td>
              채용홈페이지 및 채용관리 <br>
              전산시스템의 운영 및 관련 민원처리
            </td>
          </tr>
          <tr>
            <td>
              주식회사 구름
            </td>
            <td>
              채용지원자 코딩테스트 운영
            </td>
          </tr>
          <tr>
            <td>
              KL파트너즈, br
              로버트월터스코리아 주식회사, br
              알프레드에이치알, br
              이노서치, br
              인사이트HRG, br
              ㈜맨파워코리아, br
              ㈜에이치알그룹, br
              ㈜키스컨설팅, br
              ㈜핀커스코리아, br
              HR 코리아, br
              유어사이드, br
              인터핏, br
              레디메, br
              이지팜, br
              HTA, br
              멘쉬컨설팅, br
              석세스코리아, br
              아이비커리어
            </td>
            <td>
              인력채용 용역 서비스
            </td>
          </tr>
          <tr>
            <td>
              지피랩 주식회사
            </td>
            <td>
              기프티콘 발송 대행
            </td>
          </tr>
          <tr>
            <td>
              ㈜인투온
            </td>
            <td>
              부스제작, 설치, 임대 및 해체와 보관업무 <br>
              판촉물의 보관 및 배송 업무
            </td>
          </tr>
          <tr>
            <td>
              ㈜이노윙
            </td>
            <td>
              뷰노 아카데미 웹사이트 운영 업무
            </td>
          </tr>
          <tr>
            <td>
              국민은행 증권대행부
            </td>
            <td>
              유가증권 명의개서, 증권발행, 주식배당금 및 사채원리금의 지급대행 등 증권대행업무
            </td>
          </tr>
          <tr>
            <td>
              삼성증권 주식회사
            </td>
            <td>
              전자투표 관리서비스
            </td>
          </tr>
          <tr>
            <td>
              주식회사 디투에스, <br>
              ㈜서울씨알오, <br>
              ㈜사이넥스, <br>
              ㈜프로메디스, <br>
              ㈜씨알에스큐브
            </td>
            <td>
              임상시험 관련 업무, 데이터베이스 보관 및 관리 업무
            </td>
          </tr>
          <tr>
            <td>
              주식회사 아이네트호스팅
            </td>
            <td>
              서버 관리
            </td>
          </tr>
          <tr>
            <td>
              Amazon Web Service
            </td>
            <td>
              데이터 보관
            </td>
          </tr>
          <tr>
            <td>
              Google firebase
            </td>
            <td>
              서비스사용 추적 및 평가
            </td>
          </tr>
          <tr>
            <td>
              NICE평가정보㈜
            </td>
            <td>
              본인인증 서비스
            </td>
          </tr>
          <tr>
            <td>
              [우체국택배]
            </td>
            <td>
              제품의 배송
            </td>
          </tr>
          <tr>
            <td>
              CJ 대한통운
            </td>
            <td>
              제품의 배송
            </td>
          </tr>
        </table>

      </li>
      <li>
        회사는 위탁계약 체결 시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적/관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리/감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
      </li>
      <li>
        수탁자나 위탁업무의 내용이 변경될 경우 지체없이 본 방침을 통하여 공개하도록 하겠습니다.
      </li>
    </ol>

    <h2>
      제 5 조 개인정보처리 수탁
    </h2>

    <ol>
      <li>
        회사는 BoneAge, Fundus AI, Chest X-ray 등 병원에 대한 인공지능 의료기기 서비스 제공을 목적으로 서비스 이용자가 서비스 이용과정에서 입력한 환자의 정보를 처리합니다.
      </li>
      <li>
        회사는 시스템상 저장된 환자의 정보를 이용자에 대한 서비스 제공기간 동안(이용자의 회원탈퇴시까지) 보유 및 처리합니다.
      </li>
      <li>
        서비스 제공과 관련된 이용자의 개인정보 처리 현황은 각 서비스별 개인정보 처리방침에서도 확인하실 수 있습니다.
      </li>
    </ol>

    <h2>
      제6조 정보주체 및 법정대리인의 권리, 의무 및 그 행사방법
    </h2>

    <ol>
      <li>
        정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. 단, 관계법령에서 정한 의무사항이 있으면 권리 행사가 제한될 수 있습니다.
        <ul>
          <li>
            개인정보 열람요구
          </li>
          <li>
            오류 등이 있을 경우 정정 요구
          </li>
          <li>
            삭제요구
          </li>
          <li>
            처리정지 요구
          </li>
          <li>
            동의 철회권 행사
          </li>
        </ul>
      </li>
      <li>
        제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 아래 기재된 주소로 서면제출, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다. 다만, 동의 철회권의 행사는 귀하가 동의 표시한 방법과 같은 방법으로도 하실 수 있습니다.
        <ul>
          <li>
            주소: 서울시 서초구 강남대로 479, 9층
          </li>
          <li>
            전자우편 주소: privacy@vuno.co
          </li>
          <li>
            팩스번호: 02-515-6647
          </li>
        </ul>
      </li>
      <li>
        정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
      </li>
      <li>
        제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
      </li>
      <li>
        회사는 정보주체 또는 대리인이 제1항에 따른 권리를 행사한 경우 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다
      </li>
    </ol>

    <h2>
      제 7 조 처리하는 개인정보의 항목 및 방법
    </h2>

    <ol>
      <li>
        회사는 다음의 개인정보 항목을 처리하고 있습니다.
        <ol>
          <li>
            웹사이트상 서비스를 제공
            <ul>
              <li>
                Submit Inquiries(문의사항 남기기): 이용자의 성명, 지역, 소속, 전자우편 주소, 전화번호, 질의 내용
              </li>
              <li>
                VUNO Academy: 이용자의 성명, 직책, 이메일 주소, 비밀번호, 의료인일 경우 병원명∙진료과 정보, 휴대폰번호
              </li>
            </ul>
          </li>
          <li>
            뷰노몰: 이용자의 성명, 생년월일, 로그인ID, 이메일주소, 비밀번호, 휴대폰번호, 주소, 결제정보, 외국인일 경우 외국인번호(외국인 등록번호, 여권번호, 국적)
          </li>
          <li>
            임상시험을 비롯한 각종 연구의 실시
            <ul>
              <li>
                성명, 주소, 생년월일, 소속, 전화번호, 이메일 주소, 면허번호, 학력사항, 자격, 분과, 교육, 전문 분야, 업무 경력 등 경력사항, 저서, 논문 출판 사항, 연구경험 및 임상시험관리기준 등 관계 교육 이수 여부, 이외 연구자가 제출한 이력서에 기재된 정보
              </li>
              <li>
                자문/강연 신고 관련 정보: 자문의 경우(자문일자 또는 자문기간, 행사명 등 자문 의뢰 목적, 자문자 성명 및 소속, 자문료 지급액, 자문주제), 강연의 경우(강연일자, 강연장소, 행사명 등 강연 의뢰목적, 강연자 성명 및 소속, 강연료 지급액, 강연주제)
              </li>
              <li>
                음성정보
              </li>
            </ul>
          </li>
          <li>
            영업활동
            <ul>
              <li>
                고객의 성명, 근무처(소속 병원 및 과), 이메일, 전화번호
              </li>
            </ul>
          </li>
          <li>
            거래활동
            <ul>
              <li>
                상호, 사업자번호, 소속 임직원의 성명, 주소, 계좌번호, 이메일 주소, 전화번호, 휴대전화번호 기타 회사에 제공한 명함에 기재된 개인정보
              </li>
              <li>
                후원 단체 관계자의 성명, 행사 참여자의 이름, 담당자 이름, 연락처, 주소, 계좌번호, 혜택 대상자의 이름, 연락처, 이메일, 소속
              </li>
            </ul>
          </li>
          <li>
            민원 처리 및 안전성정보 관리
            <ul>
              <li>
                이름, 생년월일 또는 나이, 성별, 전화번호, 주소, 이메일, 담당병원 및 담당의사(의사인 경우 전공과목, 근무형태 및 소속병원)
              </li>
              <li>
                민감정보: 건강정보(치료영역, 질의분야, 제품정보, 안전성 정보 내용 및 기간 등)
              </li>
            </ul>
          </li>
          <li>
            인재채용(입사지원)
            <ul>
              <li>
                이름, 주소, 국적, 전화번호, 보훈 및 장애인 대상여부, 학력, 성적, 병역, 경력, 지식재산권/논문/저서/연구실적, 해외 체류경험 및 연수활동, 사회/봉사활동, 어학 및 기타자격, 자기소개, 사진, 생년월일, 학력, 자격증, 경력사항, 외국어능력, 지원자가 이력서에 기재한 정보, 기타 지원자가 수집에 동의한 정보
              </li>
            </ul>
          </li>
          <li>
            미디어 활동
            <ul>
              <li>
                기자의 성명, 소속, 전자우편, 전화번호 등 명함에 기재된 정보 또는 홈페이지에 공개적으로 게시된 정보
              </li>
            </ul>
          </li>
          <li>
            세금계산서 발행
            <ul>
              <li>
                고객의 성명, 사업자등록번호, 법인등록번호, 사업자 주민등록번호, 전화번호, 이메일 주소, 생년월일
              </li>
            </ul>
          </li>
          <li>
            CCTV 영상
          </li>
          <li>
            Hativcare Application <br>
            (필수 일반 개인정보) 이름, 휴대폰 번호, 이메일, 암호화된 이용자 확인값(CI), 생년월일, 성별, 기기모델, OS 버전, 기기 ID 등 모바일 기기로부터의 기본적인 정보, 만 14세 미만 가입자의 경우 법정 대리인의 정보(이름, 휴대폰 번호) (필수 민감정보) 심전도 측정 데이터, 평균 심장 박동수 및 측정 시간, 신장, 체중, 혈압, 혈당, 체온, 기타 증상 등을 포함한 기타 개인정보 <br>
            (만14세 미만 아동의 개인정보 처리에 관한 사항) 회사는 만 14세 미만 아동에 대해 개인정보를 수집할 때 아동에게 법정대리인의 성명, 연락처 정보를 요구할 수 있으며, 적법한 법정대리인이 동의하였는지를 확인합니다.
          </li>
        </ol>
      </li>
      <li>
        회사는 제1호의 개인정보는 다음과 같은 방법 또는 과정을 통하여 수집합니다.
        <ul>
          <li>
            웹사이트상 서비스(Submit Inquiries(문의사항 남기기) 등록) 신청 과정에서 수집
          </li>
          <li>
            의료기기 임상시험 등 연구 수행시 연구자 이력서를 전달받음으로서 수집
          </li>
          <li>
            영업활동을 위하여 컨퍼런스에 참석한 보건의료인의 명함을 제공받음으로서 수집
          </li>
          <li>
            거래상대방과 각종 계약 체결 과정에서 수집
          </li>
          <li>
            입사지원자의 입사지원을 통하여 수집
          </li>
          <li>
            기자의 명함 수령 또는 홈페이지에 게재된 공개 정보를 수집
          </li>
          <li>
            기판매 의료기기에 대한 부작용 보고(민원 제공) 과정에서 수집
          </li>
          <li>
            국책과제, IIT, SIT 연구과정에서 수집
          </li>
          <li>
            Hativcare Application 회원 가입 및 사용자 정보 등록을 통한 수집
          </li>
          <li>
            Hativcare Application에 연결된 기기를 통한 추가 정보 수집 (Hativ P30)
          </li>
        </ul>
      </li>
    </ol>

    <h2>
      제 8 조 개인정보의 파기
    </h2>

    <p>
      회사는 개인정보 보유기간의 경과, 개인정보 처리목적의 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
    </p>

    <ol>
      <li>
        파기절차 <br>

        수집된 개인정보는 보유기간 경과 또는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. <br>
        이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
      </li>
      <li>
        파기방법 <br>
        종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기하며, 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 이용하여 파기합니다.
      </li>
    </ol>

    <h2>
      제 9 조 개인정보의 안전성 확보 조치
    </h2>

    <p>
      회사는 개인정보 보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
    </p>

    <ol>
      <li>
        관리적 조치 <br>
        내부관리계획 수립/시행, 정기적 직원 교육 등
      </li>
      <li>
        기술적 조치 <br>
        개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
      </li>
      <li>
        물리적 조치 <br>
        전산실, 자료보관실 등의 접근통제
      </li>
    </ol>

    <p>
      가명정보의 경우, 상기 내용에 더하여 아래를 조치를 취하고 있습니다.
    </p>

    <ol>
      <li>
        가명정보와 추가정보의 분리 보관. 다만, 추가정보가 불필요한 경우에는 추가정보를 파기합니다.
      </li>
      <li>
        가명정보와 추가정보에 대한 접근 권한의 분리
      </li>
      <li>
        아래 사항을 포함하는 가명정보 처리 관련 기록 보관
        <ul>
          <li>
            가명정보 처리의 목적
          </li>
          <li>
            가명처리한 개인정보의 항목
          </li>
          <li>
            가명정보의 이용내역
          </li>
          <li>
            제3자 제공 시 제공받는 자
          </li>
          <li>
            그 밖에 가명정보의 처리 내용을 관리하기 위하여 보호위원회가 필요하다고 인정하여 고시하는 사항
          </li>
        </ul>
      </li>
    </ol>

    <h2>
      제 10조 개인정보 보호책임자
    </h2>

    <ol>
      <li>
        회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. <br>
        개인정보 보호책임자
        <ul>
          <li>
            성명 : 박종훈
          </li>
          <li>
            소속 및 직책 : SW개발본부/본부장
          </li>
          <li>
            전화번호 및 이메일주소 : 02-515-6646 / privacy@vuno.co
          </li>
        </ul>
      </li>
      <li>
        정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
      </li>
    </ol>

    <h2>
      제 11 조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
    </h2>

    <p>
      회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 수시로 저장하고 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자의 컴퓨터 하드디스크에 저장됩니다. 이후 이용자가 웹사이트에 방문할 경우 웹 사이트 서버는 이용자의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다.
    </p>

    <p>
      이용자(정보주체)는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 정보주체는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 정보주체가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
    </p>

    <p>
      쿠키 설치 허용 여부를 지정하는 방법(인터넷 익스플로어의 경우)
    </p>

    <ul>
      <li>
        웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보. 단, 이용자가 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
      </li>
    </ul>

    <h2>
      제 12 조 영상정보처리기기 설치∙운영
    </h2>

    <p>
      회사는 아래와 같이 영상정보처리기기를 설치∙운영하고 있습니다.
    </p>

    <ol>
      <li>
        영상정보처리기기 설치근거·목적 : 회사의 시설안전 및 화재예방, 범죄 예방 및 수사, 물류 작업의 투명성 확보 및 고객 민원 대응
      </li>
      <li>
        설치 대수, 설치 위치, 촬영 범위 및 영상정보처리기기의 종류
        <ul>
          <li>
            지하2층 (총8대, 고정형) : 출입구 1대(로비 및 복도), 서버실 입구 1대(서버실 입구 로비), 서버실 내 3대(서버실 내부), 하티브 창고 내 3대(창고 내부)
          </li>
          <li>
            8층: 출입구 1대(로비 및 복도, 고정형)
          </li>
          <li>
            9층: 출입구 1대(로비 및 복도, 고정형)
          </li>
          <li>
            10층: 출입구 1대(로비 및 복도, 고정형)
          </li>
          <li>
            11층: 출입구 1대(로비 및 복도, 고정형)
          </li>
        </ul>
      </li>
      <li>
        관리책임자, 담당부서 및 영상정보에 대한 접근권한자
        <ul>
          <li>
            관리책임자: 총무팀 팀장
          </li>
          <li>
            열람권자: 경영지원본부 본부장, IT보안인프라팀 팀장, 인사팀 팀장, 하티브팀 물류포장담당자(하티브 창고 내 설치된 기기에 한함)
          </li>
        </ul>
      </li>
      <li>
        영상정보 촬영시간, 보관기간, 보관장소, 처리방법
        <ul>
          <li>
            촬영시간 : 24시간 촬영
          </li>
          <li>
            보관기간 : 촬영시부터 180일
          </li>
          <li>
            보관장소: 서버실
          </li>
          <li>
            처리방법 : 개인영상정보의 목적 외 이용, 제3자 제공, 파기, 열람 등 요구에 관한 사항을 기록·관리하고, 보관기간 만료 시 복원이 불가능한 방법(시스템 자동 삭제)으로 영구 삭제
          </li>
        </ul>
      </li>
      <li>
        영상정보 확인 방법 및 장소 : 관리책임자에 요구
      </li>
      <li>
        정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람·제공·삭제 요청서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의생명·신체·재산 이익을 위해 필요한 경우에 한해 열람을 허용함
      </li>
      <li>
        영상정보 보호를 위한 기술적·관리적·물리적 조치:
        <ul>
          <li>
            내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장·전송기술 적용, 처리기록 보관 및 위·변조 방지조치, 보관시설 마련 및 잠금장치 설치 등
          </li>
        </ul>
      </li>
      <li>
        그 밖에 영상정보처리기기의 설치·운영 및 관리에 필요한 사항 : 회사는 법이 허용하지 않은 목적으로는 공개된 장소에 영상정보처리기기를 운영하지 않으며, 또한 목욕실, 화장실, 발한실, 탈의실 등 개인의 사생활을 현저히 침해할 우려가 있는 장소의 내부에는 영상정보처리기기를 설치, 운영하지 않습니다. 회사는 영상정보처리기기를 임의로 조작하거나 녹음기능을 사용하지 않습니다.
      </li>
    </ol>

    <h2>
      제 13조 제3자 링크에 관한 사항
    </h2>

    <p>
      회사 홈페이지는 제3자의 웹사이트, 플러그 인(plug-in), 응용 프로그램에 대한 링크를 포함할 수 있습니다. 정보주체가 링크를 클릭하거나 연결하는 경우, 제3자가 정보주체의 개인정보를 수집하거나 이용하게 될 수 있습니다. 회사는 이러한 제3자의 웹사이트, 플러그 인(plug in), 응용 프로그램을 제어하고 있지 않으며, 제3자의 개인정보 처리에 대한 책임을 지지 않습니다.
    </p>

    <h2>
      제 14조 개인정보 처리방침 변경
    </h2>

    <ol>
      <li>
        이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
      </li>
      <li>
        개인정보 처리방침의 변경 내역은 아래와 같습니다.
        <ul>
          <li>
            개정 일자: 2020. 1. 1.
          </li>
          <li>
            개정 일자: 2020. 3. 10.
          </li>
          <li>
            개정 일자: 2020. 11. 9.
          </li>
          <li>
            개정 일자: 2021. 8. 25.
          </li>
          <li>
            개정 일자: 2022. 1. 4
          </li>
          <li>
            개정 일자: 2023 1. 4
          </li>
          <li>
            개정 일자: 2023. 3. 7.
          </li>
          <li>
            개정 일자: 2023. 7. 20.
          </li>
          <li>
            개정 일자: 2023. 12. 28.
          </li>
          <li>
            개정 일자: 2024. 02. 12.
          </li>
          <li>
            개정 일자: 2024. 12. 09.
          </li>
        </ul>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: 'PrivateKo',
}
</script>

<style lang="sass" scoped>
.private-wrap
  padding: 1rem 0
  text-align: justify

h1
  margin: 1rem 0

h2
  margin: 2.5rem 0 1rem

p
  margin-bottom: 1rem
  text-indent: .5rem

ol, ul
  padding-top: .5rem

  ol
    list-style-type: none
    counter-reset: list-counter

    > li
      counter-increment: list-counter

      &::marker
        content: counter(list-counter) ") "

li
  margin-bottom: .5rem

table
  width: 100%
  margin: 1rem 0

  caption
    font-size: .875rem
    text-align: start

  tr
    th
      text-align: center
      border: 1px solid black
      padding: .25rem .5rem
      background-color: lightgray

    td
      font-size: .875rem
      text-align: start
      border: 1px solid black
      padding: .25rem .5rem
</style>
