import Vue from 'vue'
import Vuex from 'vuex'

import stateCommon from "./stateCommon"
import stateIO from "./stateIO"
import stateNotice from "./stateNotice"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...stateCommon,
    ...stateIO,
    ...stateNotice,
    showOldPrivate: Date.now() < (new Date('2024-12-09 00:00:00')).getTime(),
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
